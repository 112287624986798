<template>
  <div class="installationIpList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(ipList)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(ipList)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              class="btn-group"
            >
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  icon="edit"
                />
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="gray"
                  icon="trash"
                />
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <InstallationIpAdd
          v-if="addingElement"
          :installation-id="installationId"
          :type="type"
          @reload="reloadIpAddress"
        />
        <InstallationIpEdit
          v-else
          :installation-id="installationId"
          :installation-ip="selectedIp"
          :type="type"
          @reload="reloadIpAddress"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="selectedIp">
        {{ $t('sureToDelete', {'0' : selectedIp.ipAddress}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeIp()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import InstallationIpEdit from '@/components/Installation/InstallationIpEdit.vue';
import InstallationIpAdd from '@/components/Installation/InstallationIpAdd.vue';
import Sidebar from '@/components/Base/Sidebar.vue';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "InstallationIpList",
  components: {
    Sidebar,
    InstallationIpEdit,
    InstallationIpAdd,
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      ipList: null,
      showSidebar: false,
      addingElement: false,
      selectedIp: null,
      kgm_columns: [
        {
          field: 'ipAddress',
          filterable: true,
          filterCell: 'textFilter',
          filter: 'text',
          title: 'IP',
        },
        {
          field: 'expiry',
          filterable: false,
          filter: 'text',
          title: `Expiry  in day's`,
          hidden: this.type == 'static' 
        },
        {
          field: 'active',
          filterable: true,
          filter: 'boolean',
          title: this.$t('active')
        },
        {
          field: 'description',
          filterable: true,
          filterCell: 'textFilter',
          filter: 'text',
          title: `Description`
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  created () {
    this.getIps();
  },
  methods: {
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.showSidebar = true;
    },
    showSidebarEdit (ipAddress) {
      this.selectedIp = ipAddress;
      this.addingElement = false;
      this.showSidebar = true;
    },
    openConfirmModal (ip) {
      this.selectedIp = ip;
      this.$refs.confirmDelete.open();
    },
    reloadIpAddress () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getIps();
    },
    //#endregion
    //#region API-calls
   removeIp () {
      this.axios.delete(`/Installation/InstallationIp?id=${this.selectedIp.id}`, )
        .then(async () => {
          this.$snotify.success(this.$t('ipDeletedSuccessfully'));
          this.reloadIpAddress();
        })
        .finally(() => {
          this.$refs.confirmDelete.close();
        });
    },
    getIps () {
      this.loading = true;
      this.axios.get(`/Installation/InstallationIps?installationId=${ this.installationId }&type=${ this.type }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.ipList = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.installationIpList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>