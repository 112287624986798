<template>
  <!--
    Used to create a InstallationIp
  -->
  <div class="installationIpAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t("installation") }} IP
        </h4>
        <hr class="m-0 mb-3">
        <label>IP address</label>
        <input
          v-model="ip.ipAddress"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <textarea
          id="comment"
          v-model="ip.description"
          rows="3"
          class="form-control"
        />
        <template v-if="type == 'dynamic'">
          <hr class="m-0 mb-3">
          <label>Expiry in days</label>
          <input
            v-model="ip.expiry"
            class="form-control"
            type="number"
            :min="1"
            :max="30"
          >
        </template>
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!ip.ipAddress"
        @click.prevent="addInstallationIp()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t("add") }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "InstallationIpAdd",
  props: {
    installationId: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      ip: {
        expiry: 1
      },
    };
  },
  methods: {
    async addInstallationIp () {
      this.ip.installationId = this.installationId;
      this.ip.type = this.type;
      await this.axios
        .post(`/Installation/InstallationIp`, this.ip)
        .then(async (res) => {
          if (res) {
            this.$snotify.success(this.$t("ipAddedSuccessfully"));
            this.$emit("reload");
          }
        });
    }
  },
};
</script>
<style scoped>
.installationIpAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
