<template>
  <!--
    Used to edit a InstallationIp
  -->
  <div class="installationIpEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t("installation") }} IP
        </h4>
        <hr class="m-0 mb-3">
        <label>IP address</label>
        <input
          v-model="ip.ipAddress"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <textarea
          id="comment"
          v-model="ip.description"
          rows="3"
          class="form-control"
        />
        <template v-if="type == 'dynamic'">
          <hr class="m-0 mb-3">
          <label>Expiry in days</label>
          <input
            v-model="ip.expiry"
            class="form-control"
            type="number"
            :min="1"
            :max="30"
          >
        </template>
        <div class="form-check form-check-inline mb-3 mt-2">
          <input
            id="startsWith"
            v-model="ip.active"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="startsWith"
          >Active</label>
        </div>
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!ip.ipAddress"
        @click.prevent="editInstallationIp()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('save') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "InstallationIpEdit",
  props: {
    installationIp: {
      type: Object,
      required: true,
    },
    installationId: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      ip: {},
    };
  },
  created () {
    this.ip = JSON.parse(JSON.stringify(this.installationIp));
  },
  methods: {
    async editInstallationIp () {
      this.ip.installationId = this.installationId;
      this.ip.type = this.type;
      await this.axios
        .put(`/Installation/InstallationIp`, this.ip)
        .then(async (res) => {
          if (res) {
            this.$snotify.success(this.$t("ipAddedSuccessfully"));
            this.$emit("reload");
          }
        });
    }
  },
};
</script>
<style scoped>
.installationIpEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
